import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import ButtonFfort from '../elements/button';
import PostItExplodedView from '../dashboard/post-it';
import EdittedPost from './edittedPost';

class addMyDesk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPostIt:'',
            user: '',
            postits:[],
            author:'',
            notes:'',
            edditing:false,
            edditedObject:[],
            categories:[],
            selectedCat: '',
            selectedbgcolor:'',
            currentSprint:0,
            test:0,
            zIndexCount:0,
            test:[]
        }
        this.closeInstruction = this.closeInstruction.bind(this);
        this.fetchItems = this.fetchItems.bind(this);

    }

    closeInstruction(){
      console.log('lukt');
      this.setState({
          edditing: false
      })
  }

  countItems(){
    let ref = Firebase.database().ref('/items/');
    ref.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        //let state = childSnap.val();
        this.setState({
            zIndexCount: this.state.zIndexCount ++,
        })


        });
    })
        
  }


  fetchItems(){
    this.setState({
        postits:[],

    })
    let ref = Firebase.database().ref('/items/');
    ref.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user,notes: state.notes, public: state.public, match: state.match,category: state.category, selectedkeycat: state.selectedkeycat, bgcolor: state.bgcolor, zIndex: state.zIndex};
        this.setState(prevState => ({
          
            postits: [...prevState.postits, newelement],
        }));
        

        });
        
    })
  
    /*
    let ref = Firebase.database().ref('/users/'+this.state.user+'/items');
    ref.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        var newelement = {author: state.author, postit: state.postit, title: state.postit, status: state.status, key: state.key, user: state.user,notes: state.notes, public: state.public, match: state.match};
        this.setState(prevState => ({
          
            postits: [...prevState.postits, newelement],
        }));

        });
    })*/
    let refUserInfo = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
    refUserInfo.on('value' , snapshot => {
   
        let state = snapshot.val();
        this.setState({
          author: state.author


        })
    });

    let categories = Firebase.database().ref('/admin/categories/');
    categories.on('value' , snapshot => {
   
        let state = snapshot.val();
        this.setState({
        categories: state


        })
    });





  }
   
    handleSignout(){
        Firebase.auth().signOut();
    }

    componentDidMount(){
        this.setState({
            user:Firebase.auth().currentUser.uid,
            zIndexCount:0

        })

this.fetchItems();
this.countItems();


    }

    addPostIt(){
        if(this.state.newPostIt != '' && this.state.category != ''){
            this.setState({
                postits:[],
                errorMessage: false
            })
            var newPostKey = Firebase.database().ref().child('/items/').push().key;
              Firebase.database().ref('/items/'+newPostKey).set({
                author: this.state.author,
                key: newPostKey,
                match: newPostKey,
                notes:this.state.notes,
                category: this.state.selectedCat,
                bgcolor: this.state.selectedbgcolor,
                selectedkeycat: this.state.selectedkeycat,
                public: false,
                status: 'inactive',
                user: this.state.user,
                title: this.state.newPostIt,
                zIndex: this.state.postits.length+1,
                x: 0,
                y:0,
                isArchived: false,
                dateArchived: "0"
            }).then((data)=>{
                this.setState({
                    newPostIt:'',
                    notes:'',
                    selectedCat:'',
                })
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }else{
            this.setState({
                errorMessage:true
            })
        }
       
  
      }

      editPost(item){
          this.setState({
              edditedObject: item,
              edditing: true,
              postits:[]
          })

      }

      setCategory(event){
          for(let i = 0 ; i< this.state.categories.length; i++){
              if(this.state.categories[i].key == event.target.value){
                  this.setState({
                    selectedCat: this.state.categories[i].name,
                    selectedbgcolor:this.state.categories[i].bgcolor,
                    selectedkeycat: this.state.categories[i].key
                  })
              }
          }

      }


      deletePost(item){
        Firebase.database().ref('/items/'+item.match).remove().then((data)=>{
            this.setState({
                postits:[],
            })
            this.fetchItems();
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        });

        /*
        Firebase.database().ref('/users/'+this.state.user+'/items/'+item.match).remove().then((data)=>{
            this.setState({
                postits:[],
            })
            this.fetchItems();
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        });*/
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <Row className="equal-heights">
                <Col lg="8" className="col-height">
    
                    <div className="one-block-white">
                        <h4>Overzicht</h4>
                        <li className="table-head">Omschrijving <span className="action-head">Actie</span><span className="status-head add">Status</span></li>
                    { this.state.postits.filter(item => item.user == this.state.user).map((item, i) => (
  <li>{item.title} <a className="action delted-action" onClick={()=>this.deletePost(item)}><i class="fas fa-times"></i></a><a className="action" onClick={()=>this.editPost(item)}><i class="far fa-edit"></i></a><span className="status add">{item.status}</span></li>
))}

                    </div>
              
                </Col>
                <Col lg="4" className="col-height">
                    <div className="one-block-white">
                        <h4>Nieuwe Post-it aanmaken</h4>
                        <input type="text" placeholder="Nieuwe post-it toevoegen" value={this.state.newPostIt} onChange={event => this.setState({ newPostIt: event.target.value, errorMessage:''})}/> <br /><br />
                        <textarea placeholder="Maak een uitgebreide notitie" value={this.state.notes} onChange={event => this.setState({ notes: event.target.value, errorMessage:'',uploaded: false})}/>
                        <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.setCategory(event)} placeholder="Selecteer een gebruiker">
                             <option value="" disabled selected>Selecteer een categorie</option>
                           {/* <option value="" disabled selected>Selecteer set}</option>*/}
                            {
                            this.state.categories.map((set, i) => (
                                <option value={set.key} key={i}>{set.name}</option>
                                ))
                            }
                        </select><br /> <br />
                        {this.state.errorMessage ?
                        <p className="error-text">Er is geen titel of categorie opgegeven.</p>:null}
                        <Button variant="primary" className="button-setup" onClick={()=>this.addPostIt()}>Voeg toe</Button>

                    </div>
                </Col>

                {this.state.edditing ? <EdittedPost postit={{object:this.state.edditedObject}} closeInstruction={this.closeInstruction} fetchItems={this.fetchItems}/>: null}
            </Row>         
        );
    }
};

export default addMyDesk;
