import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import CalendarIndex from "../components/calendar";


const Calendar = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Agenda" subtitle="Chashier'r" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <CalendarIndex />
    
  </Container>
);

export default Calendar;
