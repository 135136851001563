import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import DeleteIcon from '../../assets/images/svg/times-solid.svg'
import EditIcon from '../../assets/images/svg/pen-solid.svg'
import UndoIcon from '../../assets/images/svg/undo-alt-solid.svg'
import ArrowDownIcon from '../../assets/images/svg/chevron-down-solid.svg'

class archiveScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          questions: [],
          expandedRow: null,
        categories:[],
        archiveActive: true,
        logActive: false,
        logsArray:[],
        search: '',
        archief: 'inline',
        logsDiplay: 'none',
        searchField: false,
        user:''
        };
      }

      handleRowClick(rowId) {
        console.log('clicked');
        const currentExpandedRow = this.state.expandedRow;
    
        this.setState({ expandedRow: (rowId === currentExpandedRow) ? null : rowId })
      }

      ArchiveItemRow = (item , index) =>{

        const clickCallback = () => this.handleRowClick(item.key);

        const itemsRowsDefault =[
            <tr className={this.state.expandedRow === item.key ? 'active' :null}>
            <td></td>
            <td>{item.title}<br/></td>
            <td className="svg-initials">{item.author}</td>
            <td>{item.date}</td>
            <td className={this.state.expandedRow === item.key ? 'active-svg' :null}> <img src={UndoIcon} onClick={() => this.setBack(item)} className="svg-icon"/><img src={ArrowDownIcon} className="svg-icon right-svg-icon"  onClick={clickCallback}/></td>
          </tr>
        ]
        
        if( this.state.expandedRow === item.key){
          itemsRowsDefault.push(
          <tr className="expanded-table-row">
            <td className="expanded-table-item"></td>
            <td className="expanded-table-item"><b>Categorie:</b><br/><svg height="20" width="20"><circle cx="8" cy="8" r="4"  fill={item.categoryColor} /></svg>{item.category}</td>
            <td className="expanded-table-item"><b>Notities:</b><br/>{item.notes}</td>
            <td className="expanded-table-item"><b>Andere deelnemers:</b><br/>{item.category}</td>
            <td className="expanded-table-item"></td>
        </tr>)
        }
        return itemsRowsDefault;
      }

      componentDidMount(){
        this.setState({
           user:Firebase.auth().currentUser.uid,
        })
   let ref = Firebase.database().ref('/items');
        ref.on('value' , snapshot => {
          this.setState({
            questions:[]
          })
            snapshot.forEach((childSnap) => {

            let state = childSnap.val();
            var newelement = {key: state.key, author: state.author, title: state.title,isArchived: state.isArchived, date: state.dateArchived, category: state.category, notes: state.notes, participants: state.participants, categoryColor: state.bgcolor};
            this.setState(prevState => ({
              
              questions: [...prevState.questions, newelement],
            }));
          
            });
        })
  

        let logs = Firebase.database().ref('/logs').limitToLast(30);
        logs.on('value' , snapshot => {
          this.setState({
            logs:[]
          })
            snapshot.forEach((childSnap) => {

            let state = childSnap.val();
            var newelement = {author: state.author, date: state.date, event: state.event,key: state.key, postitKey: state.postitKey, title: state.title, user: state.user};
            this.setState(prevState => ({
              
              logsArray: [...prevState.logsArray, newelement],
            }));
          
            });
        })
      }

      setBack(item){
        Firebase.database().ref('/items/'+item.key).update({
            isArchived: false,
            
        }).then((data)=>{
         
            var newPostKey = Firebase.database().ref().child('/logs/').push().key;
            Firebase.database().ref('/logs/'+newPostKey).set({
              user: this.state.user,
              key: newPostKey,
              title: item.title,
              author: item.author,
              postitKey: item.key,
              event: 'Deze kaart is uit het archief verwijderd.'
            })
        
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (

<Row>
                <Col sm="5">

                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="6" className={this.state.archiveActive ? "button-settings left active-settings" : 'button-settings left'}>
                        <a onClick={()=>this.setState({
                          archief: 'inline',
                          logsDiplay:'none',
                          archiveActive: true,
                          logActive:false
                        })}>Gearchiveerd</a>
                    </Col>
                    <Col sm="6"  className={this.state.logActive ? 'button-settings right active-settings' : 'button-settings right'}>
                        <a onClick={()=>this.setState({
                          archief: 'none',
                          logsDiplay:'inline',
                          archiveActive: false,
                          logActive:true
                        })}>Logs</a>
                    </Col>

                  </Row>
                </Col>
                <Col sm="3">
                  <Row>
                    <Col sm="6">
                    </Col>
                    <Col sm="6">{/*
                    <input type="text" className="input-field-sets" placeholder="Zoeken" value={this.state.search} onChange={event => this.setState({ search: event.target.value, searchField: true})}  />
                    */}
                    </Col>
                  </Row>
                </Col>

                
             <Col sm="12" style={{display: this.state.archief}}>
                <table className="settings-table"> 

                  <tr>
                    <th></th>
                    <th>Titel van post-it</th>
                    <th>Verantwoordelijke</th>
                    <th>Gearchiveerd op</th>
                    <th>Acties</th>
                  </tr>

                  {
                this.state.questions.filter(item => (item.isArchived == true )).map((question, i) => (
                    this.ArchiveItemRow(question,i)
                ))

            }      
                  </table>
                  <div className="end-of-table">
                    Geen archief post-it's meer.
                  </div>
          </Col>


          <Col sm="12" style={{display: this.state.logsDiplay}}>

                <table className="settings-table"> 

                  <tr>
                    <th></th>
                    <th>Door</th>
                    <th>Datum gebeurtenis</th>
                    <th>Omschrijving</th>
                    <th>Titel post-it</th>
                  </tr>

            {
                this.state.logsArray.map((logs, i) => (
                  <tr>
                    <td></td>
                    <td>{logs.user}<br/></td>
                    <td >{logs.date}</td>
                    <td>{logs.event}</td>
                    <td>{logs.title}</td>
                  </tr>
                ))

            }      
                  </table>
                  <div className="end-of-table">
                    Geen archief post-it's meer.
                  </div>
          </Col>
        
    
         
        </Row>
        );
    }
};

export default archiveScreen;
