import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import ArchiveScreen from "../components/archive";

const Archive = () => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Archief" subtitle="APM-tool" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
    <Row>
      <Col lg="10">
          <ArchiveScreen />
       
      </Col>
    </Row>
  </Container>
);

export default Archive;
