import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';

class loggingScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          logs: [
        ],
        categories:[]
        };
      }

      componentDidMount(){
   let ref = Firebase.database().ref('/logs');
        ref.on('value' , snapshot => {
          this.setState({
            logs:[]
          })
            snapshot.forEach((childSnap) => {

            let state = childSnap.val();
            var newelement = {author: state.author, date: state.date, event: state.event,key: state.key, postitKey: state.postitKey, title: state.title, user: state.user};
            this.setState(prevState => ({
              
              logs: [...prevState.logs, newelement],
            }));
          
            });
        })
  
      }


    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
          <Row>
          <Col lg="12" className="archive-list">
            {
              this.state.logs.map((log, i) => (
            <Row className="log-block">
                <Col md="6"><span className="title">Door:</span>  {log.user}</Col>
                <Col md="6"><span className="title">Op:</span> {log.date} </Col>
                <Col md="12"><span className="title"><br />Gebeurtenis:</span><br />{log.event}</Col>
                <Col md="12"><span className="title"><br />Title post-it:</span> {log.title}</Col>
            </Row>
              ))

            }
            
          </Col>
         
        </Row>
        );
    }
};

export default loggingScreen;
