

export default function() {
  return [

    {
      title: "Alle apps",
      htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
      to: "/dashboard",
    }
    
  ];
}
