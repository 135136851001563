import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import ButtonFfort from '../elements/button';
import { ChromePicker } from 'react-color';


class AppDetailCategoryAPM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            color:'',
            po:'',
            uploaded:false,
            background: '',
            error: false,
            errorMessage:''
        }

      }


      componentDidMount(){
          this.setState({
              name: this.props.data.category.name,
              background: this.props.data.category.bgcolor,
              po:this.props.data.category.po
          })
      }

      updateCategoryPropeties(){
        this.setState({
            error: false
        })

      if(this.state.name != ''){

        Firebase.database().ref('/admin/apps/'+this.props.data.projectKey+'/clients/'+this.props.data.clientKey+'/settings/categories/'+this.props.data.category.position).update({
            bgcolor: this.state.background,
            name:  this.state.name,
            po: this.state.po
             
           }).then((data)=>{
               this.setState({
                uploaded: true
               })
              
           }).catch((error)=>{
               //error callback
               console.log('error ' , error)
           })
        }else{
            this.setState({
                error: true,
                errorMessage: 'Er zijn velden niet ingvult.'
            })
        }
      }

      handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
      };
      handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleClose = () => {
        this.setState({ displayColorPicker: false })
      };
          

      

        
          
    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
          }
          const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }
        return (
            <Row >
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="edit-screen-detail">
                        
                        <h4>Categorie bewerken</h4>
                        <br/>
                        <li><span className="align-left">Naam</span> <span className="align-right"><input type="text" value={this.state.name} onChange={(event)=> this.setState({name: event.target.value, uploaded: false})} /></span></li>
                        <li><span className="align-left">Kleur</span> <span className="align-right"><div>
        <button onClick={ this.handleClick }>Selecteer een kleur</button>
        { this.state.displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleClose }/>
          <ChromePicker color={ this.state.background }
        onChangeComplete={ this.handleChangeComplete } />
        </div> : null }
      </div><div style={{padding:'1% 2%',width:'15%',marginTop:'-5%', height: '25px', backgroundColor: this.state.background, float:'right', borderRadius:'5px'}}></div></span></li>
                        <li><span className="align-left">Product owner</span> <span className="align-right"><input type="text" value={this.state.po} onChange={(event)=> this.setState({po: event.target.value, uploaded: false})} /></span></li>
                        
                        <span className="marginTop">
                       
                       
                 
                        </span>

                        {this.state.error ?
                            <span className="marginTop">
                                <Button className="save-button" variant="primary" onClick={()=> this.updateCategoryPropeties()}>Opslaan</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Categorie is bijgewerkt.</span> :null}<br />
                                <span className="message">{this.state.errorMessage}</span>                        
                            </span>
                            :                   
                        <span className="marginTop">  
                             <Button className="save-button" variant="primary" onClick={()=> this.updateCategoryPropeties()}>Opslaan</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Categorie is bijgewerkt.</span> :null}<br />
                        </span>
                        }

                       
                       
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default AppDetailCategoryAPM;
