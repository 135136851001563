import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';

const apps = [
  {key: 0, name: 'APM-tool', po: 'Jordie van Berkel', status: 'In productie', dateLastUpdate: '28-09-2020', version: '1.0.2'},
  {key: 1, name: 'Quick-scan tool', po: 'Coert van Santen', status: 'In ontwikkeling', dateLastUpdate: '28-09-2020', version: '0.1'},
  {key: 2, name: 'Q-sort', po: 'Jessica van Hees', status: 'In ontwikkeling', dateLastUpdate: '28-09-2020', version: '0.2'},
]

class DashboardIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          apps:[]
        }
    }



  


    componentWillMount() {
      let apps = Firebase.database().ref('/admin/apps');
      apps.once('value' , snapshot => {
          snapshot.forEach((childSnap) => {

          let state = childSnap.val();
          var newelement = {key: state.key, name: state.name, po: state.po,status: state.status, dateLastUpdate: state.dateLastUpdate, version: state.version, image: state.image,shortcode: state.shortcode};
          this.setState(prevState => ({
            
            apps: [...prevState.apps, newelement],
          }));
        
          });
      })
      
    }


   
    render() {

        return (
            <Row>

                   <Col lg={12}>
                   <table className="app-table" >
                      <tr>
                        <th>App</th>
                        <th>App-status</th>
                        <th>Laatst geupdate</th>
                        <th>Versie</th>
                        <th></th>
                      </tr>
                      {this.state.apps.map((app, i) => (
                          <tr className="app-table-row">
                            <td className="app-table-firstrow"><span className="app-table-icon"><img src={app.image} /></span> <span className="app-table-title">{app.name}</span> <br /> <span className="app-table-po">{app.po}</span></td>
                            <td>{app.status}</td>
                            <td>{app.dateLastUpdate}</td>
                            <td>{app.version}</td>
                            <td><Link to={{pathname:'/dashboard/app-overview', state:{key: app.key, name: app.name, shortcode: app.shortcode}}}>Bekijk</Link></td>
                          </tr>
                        ))
                      }
                    </table>

                   </Col>
                   
            </Row>



        );
    }
};

export default DashboardIndex;