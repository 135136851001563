import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";

// Route Views

import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

import Archive from "./views/ArchiveScreen";
import CategoryOverview from "./views/ArchiveCategoryScreenOverview";
import SetOverview from "./views/ArchiveSetScreenOverview";
import addArchiveScreen from "./views/ArchiveAddScreen";
import LoginScreen from './views/LoginScreen';
import Dashboard from "./views/Dashboard";
import Calendar from "./views/CalendarScreen";
import myDeskView from "./views/MyDesk";
import AddToMyDesk from "./views/AddToMyDesk";
import Logging from "./views/LoggingScreen";
import AppOverviewScreen from "./views/AppOverviewScreen";
import AppDetailOverviewScreenAPM from "./views/AppDetailOverviewScreenAPM";
import AppDetailCategoryScreenAPM from "./views/AppDetailCategoryScreenAPM";
import AppDetailAddCategoryScreenAPM from "./views/AppDetailAddCategoryScreenAPM";
import AddBoardScreenApm from "./views/AddBoardScreenAPM";
import AddUserScreenApm from "./views/AddUserScreenAPM";
import AppDetailOverviewScreenQsort from "./views/AppDetailOverviewScreeQsort";



export default [
  {
    path: "/",
    exact: true,
    layout: ModalLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,

  },
  {
    exact: true,
    path:"/dashboard/app-overview",
    layout: DefaultLayout,
    component: AppOverviewScreen
  },
  {
    exact: true,
    path:"/dashboard/app-overview/add-board",
    layout: DefaultLayout,
    component: AddBoardScreenApm
  }, 
  {
    exact: true,
    path:"/dashboard/app-overview/add-user",
    layout: DefaultLayout,
    component: AddUserScreenApm
  },  
  {
    exact: true,
    path:"/dashboard/app-overview/app-details-apm",
    layout: DefaultLayout,
    component: AppDetailOverviewScreenAPM
  },  
  {
    exact: true,
    path: "/dashboard/app-overview/app-details-apm/change-category",
    layout: DefaultLayout,
    component: AppDetailCategoryScreenAPM
  },
  {
    exact: true,
    path: "/dashboard/app-overview/app-details-apm/add-category",
    layout: DefaultLayout,
    component: AppDetailAddCategoryScreenAPM
  },
  {
    exact: true,
    path:"/dashboard/app-overview/app-details-qsort",
    layout: DefaultLayout,
    component: AppDetailOverviewScreenQsort
  },

  {
    path: "/archive",
    layout: DefaultLayout,
    component: Archive
  },
  {
    path: "/loggs",
    layout: DefaultLayout,
    component: Logging
  },
  {
    path: "/category-overview/:catname",
    layout: DefaultLayout,
    component: CategoryOverview
  },
  {
    path: "/set-overview/:catname/:setname/:categorykey/:setkey",
    layout: DefaultLayout,
    component: SetOverview
  },
  {
    path: "/add-archive",
    layout: DefaultLayout,
    component: addArchiveScreen
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/agenda",
    layout: DefaultLayout,
    component: Calendar
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/my-desk",
    layout: DefaultLayout,
    component: myDeskView
  },{
    path: "/add-my-desk",
    layout: DefaultLayout,
    component: AddToMyDesk
  },
  {
    path: "/login",
    layout: ModalLayout,
    component: LoginScreen
  },
];
