import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

class CalendarIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           eventDb: []
        };
    }

    onEventResize = (data) => {
        const { start, end } = data;
    
        this.setState((state) => {
          state.events[0].start = start;
          state.events[0].end = end;
          return { events: state.events };
        });
      };
    
      onEventDrop = (data) => {
        console.log(data);
      };

    componentDidMount() {
        /*let ref = Firebase.database().ref('/category');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log('firebase output:'+state)
            var newelement = {name: state.name, key: state.key};
            this.setState(prevState => ({
              
              categories: [...prevState.categories, newelement],
            }));
            console.log('firebase'+state.name);
            });
        })
  */

 let ref = Firebase.database().ref('/events');
 ref.on('value' , snapshot => {
     snapshot.forEach((childSnap) => {
     let state = childSnap.val();
     console.log(state);
         let newEvents = {title: state.title, id: state.id, resourceId: state.resourceId,start: new Date(state.yearStart,state.monthStart,state.dayStart,state.hourStart,state.minuteStart,state.secondStart),end: new Date(state.yearStart,state.monthStart,state.dayStart,state.hourEnd,state.minuteEnd,state.secondEnd)};
         this.setState(prevState => ({
            eventDb: [...prevState.eventDb, newEvents]
         }))  
 })});
    }


    saveInfo() {
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;

        var newPostKey = Firebase.database().ref().child('/q-sort').push().key;
        Firebase.database().ref('/' + dateStamp + '/' + newPostKey).set({
            companyName: this.state.companyName,
            participants: this.state.participants,
            questions: this.state.questions,
            stelling: this.state.stelling,
            key: newPostKey,
            user: 1,
        })

        this.setState({
            key: newPostKey,
        })
    }

    render() {
        return (
            <Row>
                <Col lg="12">
                    <h6 className="titles-setup">Selecteer bord</h6>
                </Col>
                <Col lg="10">
                <DnDCalendar
          defaultDate={moment().toDate()}
          defaultView="month"
          events={this.state.events}
          localizer={localizer}
          onEventDrop={this.onEventDrop}
          onEventResize={this.onEventResize}
          resizable
          style={{ height: "100vh" }}
        />
               
                </Col>

                
            </Row>



        );
    }
};

export default CalendarIndex;