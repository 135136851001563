import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase';
import 'firebase/auth';

import {AuthProvider} from "./Auth";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"



var config ={
    apiKey: "AIzaSyDP9ILQ1I0ey8427ZLnIrjKhG9Ogtip_B8",
    authDomain: "f-fort-tools-b7c67.firebaseapp.com",
    databaseURL: "https://f-fort-tools-b7c67.firebaseio.com",
    projectId: "f-fort-tools-b7c67",
    storageBucket: "f-fort-tools-b7c67.appspot.com",
    messagingSenderId: "740554167138",
    appId: "1:740554167138:web:304c02288e858fd863923b",
    measurementId: "G-VSFKPPCFS5"
};
firebase.initializeApp(config);


ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>

, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
