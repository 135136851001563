import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col,Button} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';



class AppOverviewIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          clients:[],
          projectKey:'',
          toolName:''
        }
    }



  


    componentWillMount() {
      let apps = Firebase.database().ref('/admin/apps/'+this.props.data.key+ '/clients');
      apps.once('value' , snapshot => {
          snapshot.forEach((childSnap) => {
          let state = childSnap.val();
          var newelement = {key: state.key, employee: state.employee, name: state.name, nextSession: state.nextSession, status: state.status};
          this.setState(prevState => ({
            clients: [...prevState.clients, newelement],
          }));
        
          });
      })

      this.setState({
        projectKey: this.props.data.key,
        toolName: this.props.data.name,
        
      })
    }


   
    render() {

        return (
            
            <Row>
            <Row className="head-block">
                <Col sm={8}>

                </Col>
                <Col sm={2}> 
                  <Link to={{pathname: '/dashboard/app-overview/add-board', state:{  projectKey: this.state.projectKey, clientKey: this.state.clientKey, toolName: this.state.toolName, clientName: this.state.clientName}}}><Button onClick={()=> console.log('clicked')} className="add-button" variant="primary">Nieuw bord</Button></Link>
                </Col>
                <Col sm={2}>
                <input type="text" className="input-field-sets" placeholder="Zoeken" value={this.state.search} onChange={event => this.setState({ search: event.target.value, searchField: true})}  />
                </Col>
                
                </Row>
                
                   <Col lg={12}>
                   <table className="app-table" >
                      <tr>
                        <th>Klant</th>
                        <th>F-Fort verantwoordelijke</th>
                        <th>Status</th>
                        <th>Volgende sessie(test)</th>
                        <th></th>
                      </tr>
                      {this.state.clients.map((client, i) => (
                          <tr className="app-overview-table-row">
                            <td>{client.name}</td>
                            <td>{client.employee}</td>
                            <td>{client.status}</td>
                            <td>{client.nextSession}</td>
                            <td><Link to={{pathname:'/dashboard/app-overview/app-details-apm', state:{clientKey: client.key, projectKey: this.state.projectKey, toolName: this.state.toolName, clientName: client.name, shortcode: this.props.data.shortcode}}}>Bekijk</Link></td>
                          </tr>
                        ))
                      }
                    </table>

                   </Col>
                   
            </Row>



        );
    }
};

export default AppOverviewIndex;