import React, {useCallback, useContext} from "react";
import {withRouter, Redirect} from "react-router";
import Firebase from 'firebase';
import {AuthContext} from "../../Auth";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import LoginImage from '../../assets/images/login-image.png';
import Loader from 'react-loader-spinner'

const Login = ({history}) =>{

    const handleLogin = useCallback(
         event =>{
            event.preventDefault();
            const {email,password} = event.target.elements;
            try{
                 Firebase.auth().signInWithEmailAndPassword(email.value, password.value);
                history.push("/");
            }catch (error){
                alert(error);
            }
        },
        [history]
    );

    const { currentUser} = useContext(AuthContext);

    if (currentUser){
        return <Redirect to="/" />;
    }
    
    return (
      <Row >
      <Col lg="4"></Col>
      <Col lg="4" className="login-block-left">
      <img src={"https://firebasestorage.googleapis.com/v0/b/f-fort-tools-b7c67.appspot.com/o/logo-f-fort.png?alt=media&token=cb82ec01-193c-47f8-a229-bf115da3c881"}  className="logo-ffort"/>
          <h3 style={{color: 'black'}}>Management console</h3>
          {/**<h4>Q-sort</h4> */}
          <p>Welkom bij de eerste versie van het management console van F-Fort. Met deze console worden alle applicaties aangestuurd binnen de organisatie. Daarnaast geeft het een volledig overzicht van alle producten die F-Fort digitaliseert.</p>
          <form onSubmit={handleLogin}>
          <Row >

          <input style={{padding: 10, marginTop: 10,}} name="email" type="email" placeholder="Gebruikersnaam" />
        
          </Row>
          <Row >

              <input style={{padding: 10, marginTop: 10,}} name="password" type="password" placeholder="Wachtwoord" />
  
          </Row>
          <button style={{padding: 10,paddingLeft: 30,paddingRight: 30, marginTop: 10, backgroundColor: '#e94e1b', borderStyle: 'none', color: 'white', borderRadius:50, fontWeight:'bold' }} type="submit">Login</button>
          {/*<Loader
         type="ThreeDots"
         color="#00BFFF"
         height={100}
         width={100}
         timeout={4000} //3 secs
 
          />*/}
      </form>
      </Col>

    </Row>
    );
};


export default withRouter(Login);