import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import MyDesk from "../components/my-desk";
import AddMyDesk from "../components/my-desk/addMyDesk";
import { Link } from "react-router-dom";


const AddToMyDesk = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Mijn bureau" subtitle="APM-tool " md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{pathname: `/my-desk`,state: {}}} >Terug</Link>
      <img src={require('../assets/images/Logo-Helmond.svg')}   style={{ width: '20%', float:' right', left: '73%', position: 'relative', marginTop: '-5%', marginBottom:'2%'}}/>
    </Row>
    <AddMyDesk />
    
  </Container>
);

export default AddToMyDesk;
