import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col,Button} from "shards-react";
import Firebase from 'firebase';
import ArrowDownIcon from '../../assets/images/svg/chevron-down-solid.svg'
import EditIcon from "../../assets/images/svg/pen-solid.svg"

import { Title } from '@material-ui/icons';
import { Link } from 'react-router-dom';

class AppDetailOverviewQsort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: '',
            key: '',
            name: '',
            nextSession: '',
            status: '',
            settings:[],
            target:'',
            pieces: 0,
            description: '',
            till:'',
            users:[],
            categories:[],
            live: false,
            projectKey:'',
            clientKey:''
        }
    }



  


    componentWillMount() {
      this.setState({
        categories:[],
        projectKey: this.props.data.projectKey,
        clientKey: this.props.data.clientKey,
        toolName: this.props.data.toolName,
        clientName:  this.props.data.clientName,
      })
      let apps = Firebase.database().ref('/admin/apps/'+this.props.data.projectKey+ '/clients/'+this.props.data.clientKey);
      apps.on('value' , snapshot => {
        let state = snapshot.val();

        this.setState({
            employee: state.employee,
            key: state.key,
            name: state.name,
            nextSession: state.nextSession,
            status: state.status,
            settings: state.settings,
           

        })
      })
          
    }
    render() {

        return (
            
            <Row>
            <Row className="head-block">
                <Col sm={8}>

                </Col>
                <Col sm={2}> </Col>
                <Col sm={2}>
               
                </Col>
                
                </Row>
                
                   <Col lg={12}>
                   <table className="app-table" >
                      <tr>
                        <th>Klant</th>
                        <th>F-Fort verantwoordelijke</th>
                        <th>Status</th>
                
                        <th></th>
                      </tr>

                          <tr className="app-overview-table-row">
                            <td>{this.state.name}</td>
                            <td>{this.state.employee}</td>
                            <td>{this.state.status}</td>
                            <td><img src={"https://firebasestorage.googleapis.com/v0/b/f-fort-tools-b7c67.appspot.com/o/chevron-down-solid.svg?alt=media&token=28dbcff8-d9c4-47d4-84c0-dd649e31f503"} className="svg-icon right-svg-icon" /></td>
                          </tr>
                  
               
                    </table>

                   </Col>

                   <Col sm={6} className="margin-top">
                   <h4>Bord informatie</h4>
                      <div className="small-settings-section">
                        <li><span className="align-left">Volgende sessie</span> <span className="align-right"><input type="date" value={this.state.till} onChange={(event)=> this.setState({till: event.target.value})} /></span></li>
                        <li><span className="align-left">Status</span> <span className="align-right"> <label class="switch"><input type="checkbox" checked={this.state.live} onChange={(event)=> this.changeStatus()} /><span class="slider round"></span></label></span></li>
                        <li><span className="align-left">Doel</span> <span className="align-right"><input type="text" value={this.state.target} onChange={(event)=> this.setState({target: event.target.value})} /></span></li>
                        <li><span className="align-left">Doel omschrijving</span> <span className="textarea"><textarea  value={this.state.description} onChange={(event)=> this.setState({description: event.target.value})} /></span></li>
                        
                        <div className="settings-div">
                          <Button className="save-button" variant="primary" onClick={()=> this.updateTarget()}>Opslaan</Button>
                        </div>
                      </div>

                   </Col>
                   <Col sm={6} className="margin-top">
                   <h4>Categorieën</h4>
                      <div className="small-settings-section">
                      { this.state.categories.sort((a, b) => a.position > b.position ? 1 : -1).map((category, i) => ( 
                        <li className="category-list-item"><span className="category-item">{category.name}</span> <span className="align-right-actions"><img src={"https://firebasestorage.googleapis.com/v0/b/f-fort-tools-b7c67.appspot.com/o/times-solid.svg?alt=media&token=88adcaa8-9baa-4474-8727-dd1b762cb425"} className="svg-icon right-svg-icon arrow-up" onClick={()=> this.deleteCategory(category.key, category.position)} /><Link to={{pathname:'/dashboard/app-overview/app-details-apm/change-category', state:{ category: category, projectKey: this.state.projectKey, clientKey: this.state.clientKey, toolName: this.state.toolName, clientName: this.state.clientName}}}><img src={"https://firebasestorage.googleapis.com/v0/b/f-fort-tools-b7c67.appspot.com/o/pen-solid.svg?alt=media&token=3290b40d-8d3a-4866-94fb-a8cbed989ba0"} className="svg-icon right-svg-icon"  />{/*</Link><img src={ArrowDownIcon} className="svg-icon right-svg-icon arrow-up" onClick={()=> this.positionPlus(category.key, category.position)} /><img src={ArrowDownIcon} className="svg-icon right-svg-icon"  onClick={()=> this.positionMin(category.key, category.position)}/>*/}</Link> </span></li>
                      ))}
                        
                        
                        <div className="settings-div">
                          <Link to={{pathname: '/dashboard/app-overview/app-details-apm/add-category', state:{  projectKey: this.state.projectKey, clientKey: this.state.clientKey, toolName: this.state.toolName, clientName: this.state.clientName}}}><Button onClick={()=> console.log('clicked')} className="recover-button" variant="primary">Toevoegen</Button></Link>
  
                          <Button className="save-button" variant="primary" onClick={()=> this.updateCategory()}>Opslaan</Button>

                        </div>
                      </div>

                   </Col>
                   <Col sm={6} className="margin-top">
                   <h4>Gebruikers</h4>
                      <div className="small-settings-section">
                                {this.state.users.map((user,i) =>(
                                     <li><span className="align-left">{user.name}</span> {/*<span className="align-right"><img src={"https://firebasestorage.googleapis.com/v0/b/f-fort-tools-b7c67.appspot.com/o/times-solid.svg?alt=media&token=88adcaa8-9baa-4474-8727-dd1b762cb425"} className="svg-icon-actions" /><img src={EditIcon} className="svg-icon-actions" onClick={()=> console.log('edit user')}/></span>*/}</li>
                                ))}
                        <div className="settings-div">
                          <Link to={{pathname: '/dashboard/app-overview/add-user', state:{  projectKey: this.state.projectKey, clientKey: this.state.clientKey, toolName: this.state.toolName, clientName: this.state.clientName}}}><Button onClick={()=> console.log('clicked')} className="recover-button" variant="primary">Toevoegen</Button></Link>
                        </div>
                      </div>
                   </Col>
            </Row>
        );
    }
};

export default AppDetailOverviewQsort;