import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import DashboardIndex from "../components/dashboard";
import { Link } from "react-router-dom";
import AppDetailAddCategoryAPM from "../components/app-overview/detailAppAddCategory";


const AppDetailAddCategoryScreenAPM = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={"Categorie Aanmaken"} subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{ pathname:'/dashboard/app-overview/app-details-apm' ,state: {key: props.location.state.projectKey,projectKey: props.location.state.projectKey, name: props.location.state.toolName, toolName: props.location.state.toolName, clientKey: props.location.state.clientKey, clientName: props.location.state.clientName}}}>Terug</Link>
    </Row>
    <AppDetailAddCategoryAPM data={props.location.state}/>
    
  </Container>
);

export default AppDetailAddCategoryScreenAPM;
