import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import DashboardIndex from "../components/dashboard";
import { Link } from "react-router-dom";
import AppDetailOverviewQsort from "../components/qsort/detailAppQsort";


const AppDetailOverviewScreenQsort = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={props.location.state.toolName + " - "+ props.location.state.clientName} subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{ pathname:'/dashboard/app-overview/' ,state: {key: props.location.state.projectKey, name: props.location.state.toolName }}}>Terug</Link>
    </Row>
    <AppDetailOverviewQsort data={props.location.state}/>
    
  </Container>
);

export default AppDetailOverviewScreenQsort;
