import React, {useCallback, useContext} from "react";
import { Container, Row, Col } from "shards-react";
import Firebase from 'firebase';
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import Login from "../components/authentication/login";


import desktopImage from '../assets/images/background-blobs.png';
import mobileImage from '../assets/images/background-blobs.png';


const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
const LoginScreen = (props) => (
  <Container fluid className="main-content-container px-4 modal-background" /*style={{backgroundColor: `#e94e1b` }} */ style={{backgroundImage: `url(${imageUrl})` }}>
      {/* 
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen" subtitle="Q-sort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    
           <img src={require('../assets/images/Logo-Helmond_Wit.svg')}  style={{ width: '20%',
    float:' right',
    left: '77%',
    position: 'absolute',
    marginTop: '1%',
    marginBottom:'2%'}}/>*/}

    <Login />
    
  </Container>
);

export default LoginScreen;
